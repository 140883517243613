<template>
  <div class="inform">
    <div class="inform_top">
      <div class="left">
        <img :src="cancel" @click="toBack" alt />
      </div>
      <div class="center">接送信息</div>
    </div>
    <van-swipe-cell
      style="background: #ffffff;margin-bottom: 10px;"
      v-for="(item,index) in transferList"
      :key="index"
    >
      <div class="inform_content">
        <div class="pick_type">{{item.type===0?'固定接送人':'临时接送人'}}</div>
        <div class="detail">
          <div class="left">
            <img :src="item.picture?`${apiURL}/image?name=${item.picture}`:none" alt />
          </div>
          <div class="right">
            <p>{{user.name}}{{item.relationship}}</p>
            <p v-if="item.type === 1" class="time">有效时间 {{item.expireTime}}</p>
            <p>{{item.className}} {{user.phone}}</p>
          </div>
        </div>
      </div>
      <template #right>
        <van-button
          @click="deleteTransferFn(item.id)"
          square
          text="删除"
          type="danger"
          style="height:100%"
        />
      </template>
    </van-swipe-cell>
    <div class="inform_bottom">
      <div class="add" @click="toPage(0)">
        <div class="left">
          <i style="color:#02a7f0;font-size:20px" class="el-icon-circle-plus"></i>
        </div>
        <div class="right">添加固定接送人</div>
      </div>
      <div class="add">
        <div class="left">
          <i style="color:#02a7f0;font-size:20px" class="el-icon-circle-plus"></i>
        </div>
        <div class="right" @click="toPage(1)">添加临时接送人</div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { deleteTransferAPI, getTransferListAPI } from "@/api/pick-up";
import { Toast, Dialog } from "vant";
import none from "@/assets/photo_none.png";
export default {
  data() {
    return {
      none,
      cancel,
      transferList: [],
      user: {},
      type: null,
      apiURL: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("personInfo"));
    this.getList();
  },
  methods: {
    toPage(status) {
      const arr = [];
      const arr2 = [];
      if (this.transferList.length !== 0) {
        this.transferList.map((item) => {
          if (item.type === 0) {
            arr.push(item);
          }
          if (item.type === 1) {
            arr2.push(item);
          }
        });
      }
      if (status === 0 && arr.length !== 0) {
        Toast("固定接送人只能有一个!");
        return;
      }
      if (status === 1 && arr2.length === 2) {
        Toast("最多只能添加两个临时接送人!");
        return;
      }
      if (status === 1 && arr.length === 0) {
        Toast("请先添加固定接送人再添加临时接送人!");
        return;
      }
      this.$router.push({
        name: "PickAddUserInfo",
        query: { status: JSON.stringify(status) },
      });
    },
    getList() {
      getTransferListAPI().then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.transferList = res.data;
        }
      });
    },
    deleteTransferFn(id) {
      Dialog.confirm({
        message: "确认删除该接送信息吗?",
        getContainer: ".inform",
        cancelButtonColor: "rgba(103,181,238)",
        confirmButtonColor: "rgba(74,135,200)",
      })
        .then(() => {
          const arr = [];
          this.transferList.map((item) => {
            if (item.type === 1) {
              arr.push(item);
            }
            if (item.id === id) {
              this.type = item.type;
            }
          });
          if (this.type === 0 && arr.length !== 0) {
            Dialog.confirm({
              message: "请先删除临时接送人后再删除固定接送人!",
              getContainer: ".inform",
              cancelButtonColor: "rgba(103,181,238)",
              confirmButtonColor: "rgba(74,135,200)",
            })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            // Toast("请先删除临时接送人后再删除固定接送人!");
            return;
          }
          deleteTransferAPI(id).then((res) => {
            if (res.code === 0) {
              Toast("删除成功!");
              this.getList();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toBack() {
      this.$router.push({ name: "Setting" });
    },
  },
};
</script>

<style lang='scss' scoped>
.inform {
  .inform_top {
    height: 48px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #ddd;
    .left {
      position: absolute;
      left: 1rem;
      img {
        // width: 35px;
        height: 30px;
      }
    }
  }
  .inform_content {
    .pick_type {
      width: 100%;

      text-align: center;
      color: black;
      font-weight: 900;
      padding-top: 5px;
    }
    .detail {
      display: flex;
      padding: 10px 10px;
      font-size: 14px;
      align-items: center;
      .left {
        width: 70px;
        height: 70px;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        overflow: hidden;
        img {
          width: 70px;
          height: 70px;
        }
      }
      .right {
        width: calc(100% - 90px);
        margin-left: 10px;
        p {
          margin: 0;
        }
        p:nth-child(2) {
          margin: 12px 0;
        }
        .time {
          width: 100%;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
  }
  .inform_bottom {
    height: 100px;
    width: 100%;
    position: fixed;
    bottom: 0;
    .add {
      height: 40px;
      width: 98%;
      margin: 0 auto;
      margin-bottom: 5px;
      background: #ffffff;
      border-radius: 3px;
      display: flex;
      align-items: center;
      .left {
        margin: 0 10px;
      }
      .right {
        font-size: 14px;
        color: #02a7f0;
      }
    }
  }
  ::v-deep .van-dialog__message {
    font-size: 1.2rem;
    font-weight: 900;
    color: black;
  }
}
</style>